<template>
  <div class="post-wrapper">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="在招职位" name="first">
        <div class="header">
          <div class="citys">
            <span class="city">城市：</span>
            <el-select
              v-model="search.cityid"
              @change="cityChange"
              size="mini"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="(item,index) in citySelectList"
                :key="index"
                :label="item.cityid"
                :value="item.cityvalue"
              ></el-option>
            </el-select>
          </div>
          <div class="refresh cursorP" @click="refreshAll">
            <span>一键刷新</span>
          </div>
          <div class="publish cursorP">
            <router-link to="/enterprise/publish/" target="_blank">
              <span>发布职位</span>
            </router-link>
          </div>
        </div>
        <div
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
        >
          <div class="list-wrapper" v-for="item in jobList" :key="item.id">
            <div class="list-top">
              <div class="top-left">
                <div class="p16" @click="goRouter('/enterprise/postDetails/', item.id)">
                  <div class="flex">
                    <h2 class="line_clamp1">{{ item.jobName }}</h2>
                    <span class="nini" v-if="item.jobOtherInfo.jobInfoStatus == '1'">[招聘会]</span>
                  </div>
                  <p class="p18" v-if=" item.jobSalary">{{ item.jobSalary }}</p>
                  <p class="p18" v-else>面议</p>
                </div>
                <ul>
                  <li>
                    <span class="first" v-if="item.cityid">{{ item.cityid }}</span>
                    <span class="first" v-else>暂无城市</span>
                    <el-divider direction="vertical"></el-divider>
                  </li>
                  <li>
                    <span v-if="item.exp ">{{ item.exp }}</span>
                    <span v-else>无经验要求</span>
                    <el-divider direction="vertical"></el-divider>
                  </li>
                  <li>
                    <span v-if="item.edu">{{ item.edu }}</span>
                    <span v-else>无学历要求</span>
                    <el-divider direction="vertical"></el-divider>
                  </li>
                  <li v-if="item.distype.length >= 2">
                    多种残疾
                  </li>
                  <li v-else>
<!--                    {{item.distype[0].disType+item.distype[0].disTypeLevel}}-->
                    <span v-for="(n,i) in item.distype" :key="i">
                      {{n.disType+n.disTypeLevel}}
                    </span>
                  </li>

                </ul>
                <p>
                  招聘时间：{{ item.beginTime | formatDate }} 至
                  {{ item.endTime | formatDate }}
                </p>
              </div>
              <div class="top-center cursorP">
                <ul>
                  <li  @click="
                      $router.push({
                        path: '/enterprise/resumes/',
                        query: { idd: item.id },
                      })
                    ">
					  <!-- <router-link
					    :to="{path:'/enterprise/resumes', query: {idd: '投递' }}"
					    :title="item.name"
					    target="_blank"
					  > -->
					<p>{{ item.applyNum }}</p>
					<h4>待处理</h4>
                  </li>
                  <li @click="
                      $router.push({
                        path: '/enterprise/resumes/',
                        query: { idd: item.id,type:'3'  },
                      })
                    ">
                    <p>{{ item.agree }}</p>
                    <h4>有意向</h4>
                  </li >
                  <li @click="
                      $router.push({
                        path: '/enterprise/resumes/',
                        query: { idd: item.id,type:'33' },
                      })
                    ">
                    <p>{{ item.ms }}</p>
                    <h4>邀面试</h4>
                  </li>
                </ul>
              </div>
              <div class="top-right">
                <ul class="cursorP">
                  <li
                    @click="
                      $router.push({
                        path: '/enterprise/recruiting/',
                        query: { ids: '1' },
                      })
                    "
                  >
                    <i class="el-icon-search"></i>
                    <span>找人才</span>
                    <!-- <span>找人才</span> -->
                  </li>
                  <li @click="editComRecruitJob(item.id)">
                    <i class="el-icon-edit-outline"></i>
                    <span>编辑</span>
                  </li>
                  <li @click="downComRecruitJob(item.id, '0', '下架')">
                    <i class="el-icon-download"></i>
                    <span>下架</span>
                  </li>
                  <li @click="refreshJob(item.id)">
                    <i class="el-icon-refresh"></i>
                    <span>刷新</span>
                  </li>
                </ul>
              </div>
            </div>

            <div class="list-bottom">
              <ul>
                <li v-for="(n, i) in item.jobLabels" :key="i">
                  <p>{{ n.labelName }}</p>
                </li>
              </ul>
            </div>
          </div>
          <div v-if="status.isHide == 1 && activeName == 'first'">
            <status :type="status.type" :des="status.des"></status>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="停招职位" name="second">
        <div class="header">
          <div class="citys">
            <span class="city" v-if="false">城市：</span>
            <el-select v-model="search.cityid" @change="cityChange" clearable size="mini" v-if="false">
              <el-option
                v-for="(item,index) in citySelectList"
                :key="index"
                :label="item.cityid"
                :value="item.cityvalue"
              ></el-option>
            </el-select>
          </div>
          <div class="refresh cursorP" @click="refreshAll" v-if="activeName == 'first'">
            <span>一键刷新</span>
          </div>
          <div class="publish cursorP">
            <router-link to="/enterprise/publish/" target="_blank">
              <span>发布新职位</span>
            </router-link>
          </div>
        </div>
        <div
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
        >
          <div class="list-wrapper" v-for="item in jobList" :key="item.id">
            <div class="list-top">
              <div class="top-left">
                <div class="p16" @click="goRouter('/enterprise/postDetails', item.id)">
                  <div class="flex">
                    <h2 class="line_clamp1">{{ item.jobName }}</h2>
                    <span class="nini" v-if="item.jobOtherInfo.jobInfoStatus == '1'">[招聘会]</span>
                  </div>
                  <p class="p18" v-if=" item.jobSalary">{{ item.jobSalary }}</p>
                  <p class="p18" v-else>面议</p>
                </div>
                <ul>
                  <li>
                    <span class="first" v-if="item.cityid">{{ item.cityid }}</span>
                    <span class="first" v-else>暂无城市</span>
                    <el-divider direction="vertical"></el-divider>
                  </li>
                  <li>
                    <span v-if="item.exp ">{{ item.exp }}</span>
                    <span v-else>无经验要求</span>
                    <el-divider direction="vertical"></el-divider>
                  </li>
                  <li>
                    <span v-if="item.edu">{{ item.edu }}</span>
                    <span v-else>无学历要求</span>
                    <el-divider direction="vertical"></el-divider>
                  </li>
                  <li v-if="item.distype.length >= 2 ">
                    多种残疾
                  </li>
                  <li v-else>
                    {{ '暂无残疾类别'}}
                  </li>
                </ul>
                <p>
                  招聘时间：{{ item.beginTime | formatDate }} 至
                  {{ item.endTime | formatDate }}
                </p>
              </div>
              <div class="top-center cursorP">
                <ul>
                  <li>
                    <p>{{ item.applyNum }}</p>
                    <h4>已投递</h4>
                  </li>
                  <li>
                    <p>{{ item.agree }}</p>
                    <h4>应聘</h4>
                  </li>
                  <li>
                    <p>{{ item.ms }}</p>
                    <h4>面试</h4>
                  </li>
                </ul>
              </div>
              <div class="top-right">
                <ul class="cursorP">
                  <li @click="editComRecruitJob(item.id)">
                    <i class="el-icon-edit-outline"></i>
                    <span>编辑</span>
                  </li>
                  <li @click="downComRecruitJob(item.id, '1', '上架')">
                    <i class="el-icon-upload2"></i>
                    <span>上架</span>
                  </li>
                  <li @click="refreshJob(item.id)" v-if="false">
                    <i class="el-icon-delete"></i>
                    <span>删除</span>
                  </li>
                </ul>
              </div>
            </div>

            <div class="list-bottom">
              <ul>
                <li v-for="(n, i) in item.jobLabels" :key="i">
                  <p>{{ n.labelName }}</p>
                </li>
              </ul>
            </div>
          </div>
          <div v-if="status.isHide == 2">
            <status :type="status.type" :des="status.des"></status>
          </div>
        </div>

        <div class="no-result" v-show="jobList"></div>
      </el-tab-pane>
    </el-tabs>
    <div class="pagination marT50">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        class="work_pagination"
        :current-page.sync="job.pageNum"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="job.pageSize"
        layout="total,prev, pager, next, jumper"
        :prev-text="pagination.pre"
        :next-text="pagination.next"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { formatTimeToStr } from "@/plugins/data.js";
export default {
  components: {},
  data() {
    return {
      loading: true,
      activeName: "first",
      citySelectList: [],
      search: {
        cityid: "",
      },
      status: {
        isHide: 0,
        type: "",
        des: "暂无职位数据",
      },
      options: [
        {
          value: "选项1",
          label: "北京",
        },
        {
          value: "选项2",
          label: "杭州",
        },
        {
          value: "选项3",
          label: "上海",
        },
        {
          value: "选项4",
          label: "深圳",
        },
        {
          value: "选项5",
          label: "青海",
        },
      ],
      value: "",
      total: 0,
      jobList: [],
      form: {
        status: "",
        id: "",
      },
      job: {
        jobStatus: 1,
        pageNum: 1,
        pageSize: 9,
        cityid: "",
      },
      pagination: {
        pre: "上一页",
        next: "下一页",
      },
      params: {
        id: "",
      },
    };
  },
  // 时间筛选
  filters: {
    formatDate: function (time) {
      if (time != null && time != "") {
        var date = new Date(time);
        return formatTimeToStr(date, "yyyy/MM/dd");
      } else {
        return "";
      }
    },
  },
  methods: {
    handleClick(tab, event) {
      // index 0 在招 1停招
      this.tabIndex = tab.index;
      if (this.tabIndex == 0) {
        this.job.jobStatus = 1;
      }
      if (this.tabIndex == 1) {
        this.job.jobStatus = 0;
      }
      this.job.pageNum = 1;
      this.getPostlist();
    },
    // 城市列表
    async cityList() {
      let res = await this.$axios.get(
        "/api/app-jycy-job/getCityListByCompanyId"
      );
      if (res.data.success) {
        this.citySelectList = res.data.data;
      }
    },
    // 职位数据请求
    async getPostlist() {
      try {
        let that = this;
        // this.loading = true;
        let res = await that.$axios.post(
          "/api/app-jycy-job/getJobListByComCenter",
          that.job
        );
        if (res.data.success) {
          this.jobList = res.data.data.records;
          this.total = res.data.data.total;
          this.loading = false;
          if (this.jobList.length <= 0) {
            this.status.type = "post";
            if (this.activeName == "first") {
              this.status.isHide = 1;
              this.status.des = "快去发布职位吧!";
            }
            if (this.activeName == "second") {
              this.status.isHide = 2;
              this.status.des = "暂无下架职位！";
            }
          } else {
            this.status.isHide = 0;
          }
        } else {
          this.$message.error(res.data.msg);
          this.loading = false;
        }
      } catch (error) {
        // this.loading = false;
        this.status.isHide = 1;
        this.status.type = "wifi";
      }
    },
    // 一键刷新
    refreshAll() {
      this.getPostlist();
      this.$message.success("刷新成功");
    },
    // 刷新职位
    async refreshJob(ids) {
      if (ids) {
        this.params.id = ids;
      } else {
        this.params.id = "";
      }
      let res = await this.$api.updateJobStatus(this.params);
      if (res.data.success) {
        this.$message.success("刷新成功");
        this.getPostlist();
      } else {
        this.$message.error(res.data.msg);
      }
    },
    // 城市筛选
    cityChange(val) {
      this.job.cityid = val;
      this.job.pageNum = 1;
      this.getPostlist();
    },
    // 上下架职位
    async downComRecruitJob(ids, status, msg) {
      this.$confirm("确定" + msg + "该职位吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .post("/api/app-jycy-job/updateJobStatus", {
              id: ids,
              jobStatus: status,
            })
            .then((res) => {
              if (res.data.success) {
                this.$message.success(res.data.msg);
                this.getPostlist();
              } else {
                this.$message.error(res.data.msg);
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            center: true,
            message: "已取消",
          });
        });
    },
    //删除职位

    // 编辑职位
    editComRecruitJob(ids) {
      this.$router.push({
        path: "/enterprise/publish/",
        query: { id: ids },
      });
    },
    // 跳转详情页
    goRouter(url, id, type) {
      if (type == 0 || type == null) {
        const { href } = this.$router.resolve({
          path: url,
          query: {
            id: id,
          },
        });
        window.open(href, "_blank");
      }
    },
    // 分页
    handleCurrentChange(val) {
      this.job.pageNum = val;
      this.getPostlist();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.pageNum = 1;
      this.getPostlist();
    },
  },
  created() {
    this.getPostlist();
    this.cityList();
  },
};
</script>

<style lang="less" scoped>
@import "../../../assets/css/enterprise/post.less";
/deep/ .el-loading-spinner i {
  font-size: 40px;
}
/deep/ .el-loading-spinner {
  top: 30%;
}
/deep/ .el-loading-mask {
  top: -60px;
}
.loads {
  min-height: 200px;
  width: 100%;
}
</style>
